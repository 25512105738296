import React from "react"
import Layout from "../templates/Layout"
import Wrapper from "../templates/Wrapper"
import ContactForm from "../components/forms/Contact"

const LandingPage = () => (
  <Layout pageTitle="Contact">
    <Wrapper wrapperType="section">
      <ContactForm />
    </Wrapper>
  </Layout>
)

export default LandingPage
